.mapboxgl-ctrl-geocoder {
  font-size: 15px;
  width: 400px;
  padding: 4px 8px;
  align-items: center;
  background-color: white;
  //box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
  box-shadow: none;
}

.mapboxgl-ctrl-top-right {
  margin-top: 15px;
}

.mapboxgl-ctrl-geocoder--icon {
  display: none;
}

.mapboxgl-ctrl-geocoder--input {
  height: 36px;
  padding: 0;
}

.mapboxgl-ctrl-geocoder .suggestions {
  width: 399px;
  left: -7px;
  margin-top: 3px;
}

.mapboxgl-ctrl-bottom-right {
  margin-bottom: 45px;
}

@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder {
    font-size: 15px;
    line-height: 20px;
    max-width: 360px;
  }
}

@media screen and (max-width: 639px) {
  .mapboxgl-ctrl-geocoder {
    line-height: 20px;
    max-width: 360px;
  }
}

@media screen and (max-width: 411px) {
  .mapboxgl-ctrl-geocoder .suggestions {
    width: 95vw;
  }
}

@media screen and (max-width: 480px) {
  .mapboxgl-ctrl-bottom-right {
    margin-bottom: 80px;
  }
}